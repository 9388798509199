<template>
  <div class="manage-rewards" :class="{skeleton: !state.loaded}">
    <table class="table table-hover">
      <thead class="thead-light">
      <tr>
        <th class="name">
          <span>구분</span>
        </th>
        <th class="id">
          <span>리워드</span>
        </th>
        <th class="title">
          <span>수량</span>
        </th>
        <th>
          <span>후원 금액</span>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(s, idx) in state.stats" :key="idx">
        <td class="name">
          <span>리워드 {{ idx + 1 }}</span>
        </td>
        <td class="id">
          <span>{{ s.title }}</span>
        </td>
        <td class="title">
          <span>{{ $lib.getNumberFormat(s.count) }}</span>
        </td>
        <td>
          <span>{{ $lib.getNumberFormat(s.amount) }}</span>
        </td>
      </tr>
      </tbody>
    </table>
    <table class="table info mt-5">
      <tbody>
      <tr>
        <th>
          <span>추가 참여금액</span>
        </th>
        <td>
          <span>{{ $lib.getNumberFormat(state.summary.addAmount) }}원</span>
        </td>
      </tr>
      <tr>
        <th>
          <span>리워드 없이 참여</span>
        </th>
        <td>
          <span>{{ $lib.getNumberFormat(state.summary.amountWithoutReward) }}원</span>
        </td>
      </tr>
      <tr>
        <th>
          <span>배송비</span>
        </th>
        <td>
          <span>{{ $lib.getNumberFormat(state.summary.deliveryAmount) }}원</span>
        </td>
      </tr>
      <tr class="total_amount">
        <th>
          <span>총 참여금액</span>
        </th>
        <td>
          <span class="text-danger">{{ $lib.getNumberFormat(state.summary.expenseAmount) }}원</span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import router from "@/scripts/router";
import http from "@/scripts/http";

function Component(initialize) {
  this.name = "pageManageRewards";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    project: Object
  },
  setup() {
    const component = new Component(() => {
      for (let i = 0; i < 5; i += 1) {
        state.stats.push({
          title: "Please wait a moment",
          count: 0,
          amount: 0,
        });
      }

      load();
    });

    const state = reactive({
      loaded: false,
      stats: [],
      summary: {
        addAmount: 0,
        amountWithoutReward: 0,
        deliveryAmount: 0,
        expenseAmount: 0,
      }
    });

    const projectSeq = router.app.$route.params.projectSeq;

    const load = () => {
      state.loaded = false;
      http.get(`/api/maker/reward/${projectSeq}/stats/reward-item`).then(({data}) => {
        state.loaded = true;
        state.stats = data.body.stats;

        if (data.body.summary.length) {
          state.summary = data.body.summary[0];
        }
      });
    };

    return {component, state, load};
  }
});
</script>

<style lang="scss" scoped>
.manage-rewards {
  table {
    border-bottom: $px1 solid #e9ecef;

    td > span {
      white-space: pre-line;
    }

    &.info {
      th {
        color: #8898aa;
        background-color: #f6f9fc;
        text-align: center;
      }
    }
  }

  &.skeleton {
    table {
      th, td {
        > span {
          @include skeleton;
        }
      }
    }
  }
}
</style>